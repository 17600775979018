var render = function () {
  var _vm$fundFinancials, _vm$fundFinancials$da, _vm$widgets, _vm$newGraphs, _vm$fundFinancials2, _vm$fundFinancials2$d;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboard"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "dashboard__content"
  }, [_c('div', {
    staticClass: "dashboard__left"
  }, [_c('FundManagerCard', {
    attrs: {
      "fundManagerData": _vm.fundManagerData,
      "progressMax": _vm.progressMax,
      "progressVal": _vm.progressVal
    }
  }), _vm.showCompanyKPIs || _vm.showCapitalStructure || _vm.showTransactions || _vm.showLePicker ? _c('fund-header', {
    attrs: {
      "filters": _vm.filters,
      "selectAvailable": true,
      "showSelect": _vm.showLePicker
    },
    on: {
      "setDataDashboard": _vm.setDataDashboard,
      "updateCompany": _vm.updateCompany,
      "reloadDashboard": _vm.reloadDashboard
    }
  }) : _vm._e(), _vm.showCompanyKPIs ? _c('FundKPIs', {
    staticClass: "dashboard__kpis",
    attrs: {
      "fundKPIs": _vm.companyKPIs,
      "withLabels": true
    }
  }) : _vm._e(), _vm.showCapitalStructure || _vm.showFundFinancials && (_vm$fundFinancials = _vm.fundFinancials) !== null && _vm$fundFinancials !== void 0 && (_vm$fundFinancials$da = _vm$fundFinancials.data) !== null && _vm$fundFinancials$da !== void 0 && _vm$fundFinancials$da.length || (_vm$widgets = _vm.widgets) !== null && _vm$widgets !== void 0 && _vm$widgets.length || (_vm$newGraphs = _vm.newGraphs) !== null && _vm$newGraphs !== void 0 && _vm$newGraphs.length ? _c('div', {
    staticClass: "dashboard__data"
  }, [_vm.showFundFinancials && (_vm$fundFinancials2 = _vm.fundFinancials) !== null && _vm$fundFinancials2 !== void 0 && (_vm$fundFinancials2$d = _vm$fundFinancials2.data) !== null && _vm$fundFinancials2$d !== void 0 && _vm$fundFinancials2$d.length ? _c('div', {
    staticClass: "dashboard__card dashboard__card--full"
  }, [_c('FundFinancials', {
    attrs: {
      "fundFinancials": _vm.fundFinancials.data
    }
  })], 1) : _vm._e(), _vm.showCapitalStructure ? _c('div', {
    staticClass: "dashboard__card dashboard__card--financials"
  }, [_c('DoughtnutChart', {
    attrs: {
      "data": _vm.capitalStructureGraph,
      "title": "Capital Structure"
    }
  })], 1) : _vm._e(), _vm._l(_vm.widgets, function (widget, index) {
    return _c('Chart', {
      key: "widget-".concat(index),
      attrs: {
        "type": widget === null || widget === void 0 ? void 0 : widget.type,
        "data": widget === null || widget === void 0 ? void 0 : widget.data,
        "title": widget === null || widget === void 0 ? void 0 : widget.label
      }
    });
  }), _vm._l(_vm.newGraphs, function (chart, index) {
    return _c('NewChart', {
      key: "newChart-".concat(index),
      attrs: {
        "data": chart
      }
    });
  })], 2) : _vm._e(), _vm.showTransactions ? _c('div', {
    staticClass: "dashboard__transactions"
  }, [_c('FundTransactions', {
    attrs: {
      "transactions": _vm.transactions
    }
  })], 1) : _vm._e()], 1), _c('div', {
    staticClass: "dashboard__right"
  }, [_c('sygni-container-title', [_vm._v(" Management Team")]), _c('management-team', {
    attrs: {
      "team": _vm.managementTeam
    }
  }), _c('sygni-container-title', [_vm._v(" Information")]), _c('fund-information-card', {
    attrs: {
      "company": true,
      "legalEntity": _vm.fundManagerData
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }