















import Vue from "vue";
import Component from "vue-class-component";
import SygniCard from "@/components/layout/SygniCard.vue";
import {Prop} from "vue-property-decorator";

// echart imports
import { use } from 'echarts/core'
import { PieChart } from 'echarts/charts'
import { LegendComponent, TooltipComponent } from 'echarts/components'
import { SVGRenderer } from 'echarts/renderers'

use([LegendComponent, TooltipComponent, PieChart, SVGRenderer])

import VChart from 'vue-echarts'

// @ts-ignore
@Component({
  components: { SygniCard, VChart },
})
export default class FundChart extends Vue {
  @Prop() title: string;
  @Prop() data: any;

  optionsData: any = {
    legend: {
      top: 'top',
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '60%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        itemStyle: {
          borderWidth: 3,
          borderColor: '#fff'
        },
        labelLine: {
          show: false
        },
        data: []
      }
    ]
  }

  get options() {
    const optionsData = this.optionsData;

    if (optionsData?.series[0]) {
      optionsData.series[0].data = this.data?.chartData?.map((value: number, index: number) => {
        return { value, name: this.data?.chartLabels[index] }
      })
    }

    return optionsData
  }
}
